




import Vue from 'vue';
import ProfileCard from '@/components/Settings/Card.vue';

export default Vue.extend({
  name: 'PersonalCard',
  components: {
    ProfileCard,
  },
  data: () => ({
    content: {
      title: 'profile.cards.personal.title',
      items: [{
        icon: 'mdi-lock-outline',
        title: 'profile.cards.personal.data',
        path: '/settings/profile',
      },
      {
        icon: 'mdi-account-outline',
        title: 'profile.cards.personal.security',
        path: '/settings/security',
      },
      {
        icon: 'mdi-account-group-outline',
        title: 'profile.cards.personal.KE',
        path: '/settings/knowledge-experience',
      },
      {
        icon: 'mdi-account-outline',
        title: 'profile.cards.personal.darkMode',
        path: '',
        action: 'darkMode',
      }],
    },
  }),
});
